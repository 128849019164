section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

select {
    font-size: large;
    
}
.name{
    color: rgba(255, 255, 255, 0.082)
}

table.details {
    border-collapse: collapse;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid black;
    background: steelblue;
    margin: 10px;
    padding: 5px;
    color: white;
    width: 85%;
    margin-inline: auto;
    justify-self: stretch;
    margin-bottom: 50px;
}

td {
    padding: 5px;
    border: 1px solid black;
}

th {
    padding: 5px;
    border: 1px solid black;
}

.myCircle:hover {
    stroke: black;
}

.loader {
    display: none;
    background-color: black;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.verticalContainer {
    display:flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}