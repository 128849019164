@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2c2c2c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #282727;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-border {
  color: #007bff;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: auto !important;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.rdw-editor-wrapper{
  border: 1px solid rgba(255, 255, 255, 0.274);
  /* height: 150px; */
  /* margin: 150px; */
  width: 90%;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
  position: fixed!important;
  max-width: 270px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul{
  height: 100vh;
  overflow-y: scroll;
}

.pro-sidebar.collapsed > .pro-sidebar-inner > .pro-sidebar-layout{
  position: relative !important;
  max-width: 80px;
}

.MuiButtonBase-root{
  color: white !important;
}


/* .MuiInputBase-formControl {
  width: 180px !important;
} */


.customerdate {
  margin-top: -8px !important

}